.App {
  text-align: left;
  color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  height: var(--vh, 100vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(15px + 2.5vmin);
  /* color: white; */
}

p {
  text-align: left;
}

div {
  text-align: left;
  line-height: 1.3;
}

#root {
  display: flex;
  height: 100vh;
  flex: 1;
}

.App-link {
  color: black;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-spin-container,
.ant-spin-nested-loading {
  height: 100% !important;
}

/* body {
  background: linear-gradient(-45deg, #777777, #383838);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} */
